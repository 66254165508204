import resful from "../../services/resful.js";
export const loginService = {
    signIn: async function(data, next) {
        let res = await resful.post("/auth/sign-in", data);
        next(res);
    },
    signInFb: async function(data, next) {
        let res = await resful.post("/auth/sign-in-facebook", data);
        next(res);
    }
};

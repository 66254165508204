import { loginService } from "./login.service.js";
import CookieService from "@/services/cookie.js";

export default {
    components: {},
    data: function() {
        return {
            email: "",
            password: "",
            message_error: ""
        };
    },
    methods: {
        handleLogin() {
            const thisVue = this;
            if (!this.email) {
                $("#login-component .email-field .warning").css("display", "flex");
            }
            if (!this.password) {
                $("#login-component .pwd-field .warning").css("display", "flex");
            }
            if (this.email && this.password) {
                loginService.signIn(JSON.stringify({ email: this.email, password: this.password }), function(data) {
                    if (data.code === 200) {
                        localStorage.setItem("Authorization", data.data.token);
                        localStorage.setItem("user", JSON.stringify(data.data.user));

                        thisVue.$router.push({ name: "Dashboard" });
                    } else {
                        setTimeout(function() {
                            thisVue.message_error = "";
                        }, 1000);
                        if (data.code == "WRONG_PASSWORD") {
                            thisVue.$swal({
                                title: 'Wrong password !',
                                type: "error",
                                showConfirmButton: false,
                                timer: 2500
                            });
                            return;
                        }
                        if (data.message)
                            thisVue.$swal({
                                title: data.message,
                                type: "error",
                                showConfirmButton: false,
                                timer: 2500
                            });
                    }
                });
            }
        },
        goRegister: function() {
            this.$router.push({ name: "Register" });
        },
        goFogotPass: function() {
            this.$router.push({ name: "ForgotPassword" });
        },
        checkLoginState: function() {
            const thisVue = this;
            FB.getLoginStatus(function(response) {
                if (response.status === "connected") {
                    loginService.signInFb(JSON.stringify({ accessToken: response.authResponse.accessToken }), function(
                        data
                    ) {
                        if (data.code === 200) {
                            localStorage.setItem("Authorization", data.data.token);
                            localStorage.setItem("user", JSON.stringify(data.data.user));

                            thisVue.$router.push({ name: "Dashboard" });
                        }
                    });
                } else {
                    thisVue.$swal({
                        title: "Disruption across Facebook Platform !",
                        type: "error",
                        timer: 2500
                    });
                }
            });
        },
        checkRef() {
            const domain_name = process.env.NODE_ENV === "production" ? "appon.vn" : "";
            const ref = this.$route.query.ref;
            if (!ref) return;
            let ref_cookie = CookieService.get_cookie("ref");
            if (ref_cookie) return;
            CookieService.set_cookie("ref", ref, 30, domain_name);
        },
        nextInput() {
            $("#login-component #pwd-field").trigger("focus");
        }
    },
    mounted() {
        window.checkLoginState = this.checkLoginState;
        this.checkRef();

        $("#login-component input").on("focus", function() {
            $("#login-component .warning").hide();
        });
    }
};
